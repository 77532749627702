







































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import CouponDetail from "./Detail.vue";
import Ticketltem from "@/components/Tickets/Ticketltem.vue";
import {
  Col,
  Row,
  NavBar,
  Icon,
  Button,
  Image,
  Swipe,
  SwipeItem,
  Tabs,
  Tab,
  Popup,
  Stepper,
  Dialog,
  Checkbox,
  List,
} from "vant";
import { CouponDtos } from "@/shims.decorator";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    Mixin,
    CouponDetail,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox,
    [Stepper.name]: Stepper,
    [List.name]: List,
    [Dialog.Component.name]: Dialog.Component,
    Ticketltem,
  },
})
export default class CouponCenter extends Mixins(Mixin) {
  @Prop({ default: false, type: Boolean }) public component!: false;
  @Prop({ default: Number }) public productId!: number;
  @Prop({ default: String }) public productTypeId!: string;

  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 10,
  };
  public checked = false;
  public active = 0;
  public couponList: Array<CouponDtos> = [];
  public couponId = 0;
  public showDetail = false;
  public text = "";
  public couponType = "";
  public effectTime = "";

  mounted(): void {
    this.getCouponList(true);
  }

  /*
   * 点击领取货使用
   */

  onClick(item: CouponDtos): void {
    if (item.receivedType === "1") {
      if (this.$route.name == "coupon-center") {
        monitorEvent("OfferList_ClickReceive", "平台领券中心点击领取"); // 埋点：平台领券中心，平台领券中心点击领取
      }
      this.receiveCoupons(item);
    } else if (item.receivedType === "2") {
      let useScopeType = "";
      if (item.useScopeType) {
        useScopeType = item.useScopeType.toString();
      } else if (item.useScope) {
        useScopeType = item.useScope;
      }
      this.goUse(item);
    } else if (item.receivedType === "3") {
      if (item.receivedCount > 0 && !this.component) {
        this.goUse(item);
      }
    }
  }

  /*
   * 处理价格
   */
  dealPrice(price?: number): string {
    return price ? `${price}` : "";
  }

  /*
   * 订单金额提示
   */
  dealAmount(type?: number, Amount?: number): string {
    let name = "";
    if (type === 1) {
      name = "不限金额";
    } else {
      name = `满${Amount}可用`;
    }
    return name;
  }

  /*
   * 处理日期
   */
  dealDate(
    type?: number,
    endTimeStr?: string,
    startTimeStr?: string,
    startTime?: string,
    endTime?: string,
    effectiveDays?: number
  ): string {
    let timer = "";
    let nowTime = new Date().getTime();
    if (type === 1) {
      if (startTime && endTime) {
        if (Number(nowTime) > Number(startTimeStr)) {
          timer = `有效期至${this.formDate(String(endTimeStr))}`;
        } else {
          timer = `有效期${startTime}至${endTime}`;
        }
      } else {
        timer = "";
      }
    } else {
      if (effectiveDays) {
        timer = `领取后${effectiveDays}天内有效`;
      } else {
        timer = "";
      }
    }

    return timer;
  }

  /*
   * 处理按钮
   */
  dealName(
    type?: string,
    receivedCount?: number
  ): {
    status: number;
    name: string;
  } {
    let data = {
      status: 1,
      name: "",
    };
    if (type === "1") {
      data.status = 1;
      data.name = "领取";
    } else if (type === "2") {
      if (this.component) {
        data.name = "已领";
        data.status = 3;
      } else {
        data.name = "去使用";
        data.status = 1;
      }
    } else if (type === "3") {
      /*
      receivedType===3时为领光有两种状态
      如果我的领取数量大于0 显示去使用，否则显示领光
      */
      if (receivedCount && receivedCount > 0) {
        if (this.component) {
          data.name = "已领";
          data.status = 3;
        } else {
          data.name = "去使用";
          data.status = 1;
        }
      } else {
        data.status = 3;
        data.name = "领光";
      }
    }
    return data;
  }

  /*
   * 处理按钮下的提示
   rType:receiveQuantityType(领取数量类型，1：不限制，2:每人限领多少张)
   vType:receivedType(领取类型，1可领，2已领，3领光)
   count:receivedCount(用户领取数量)
   quantity:receiveQuantity(限制领取数量)
   当rType为1不限制用户领取时，
      如果vType为1  展示用户"持有count张"；如果vType为2 展示用户"持有count张"；如果vType为3 如果用户领取了展示已领，持有n张 如果没有领，展示领光，按钮置灰
  当rType为2时限制用户领取时
  如果vType为1 展示用户最大可领取quantity数量；如果vType为2 展示用户"持有count张/quantity张"；如果vType为3 如果用户领取了展示已领，持有n张 如果没有领，展示领光，按钮置灰
   */
  dealQuantity(
    rType?: number,
    vType?: string,
    count?: number,
    quantity?: number
  ): string {
    let res = "";
    if (rType === 1) {
      //领取
      if (vType === "1") {
        //不限制
        res = count ? `持有${count}张` : "";
      } else if (vType === "2") {
        res = count ? `持有${count}张` : "";
      } else if (vType === "3") {
        if (count! > 0) {
          res = `持有${count}张`;
        } else {
          res = "";
        }
      }
    } else {
      //使用
      if (vType === "1") {
        if ((count as number) > 0) {
          res = `持有${count}/${quantity}张`;
        } else {
          res = quantity ? `可领取${quantity}张` : "";
        }
      } else if (vType === "2") {
        res = `持有${count}/${quantity}张`;
      } else if (vType === "3") {
        if (count! > 0) {
          res = `持有${count}/${quantity}张`;
        } else {
          res = "";
        }
      }
    }
    return res;
  }
  getCouponList(first: boolean): void {
    if (this.component) {
      this.$api.goodApi.coupon.getProductCoupons(
        this.productId,
        this.productTypeId!,
        (data) => {
          this.couponList = data.data as Array<CouponDtos>;
          this.list.loading = false;
          this.list.finished = true;
          this.text = "";
        }
      );
    } else {
      if (first) {
        this.page.current = 1;
      } else {
        this.page.current++;
      }
      this.$api.goodApi.coupon.getCentreCoupons(
        {
          page: this.page.current,
          size: this.page.size,
        },
        (data) => {
          if (this.page.current === 1) {
            this.couponList = data.data.records as Array<CouponDtos>;
          } else {
            this.couponList = this.couponList.concat(
              data.data.records as Array<CouponDtos>
            );
          }
          this.list.loading = false;
          this.list.finished = data.data.current! >= data.data.pages!;
          this.text = "——我是有底线的哦——";
        }
      );
    }
  }
  receiveCoupons(item: any): void {
    if (item.receivedType !== "3") {
      this.$api.goodApi.coupon.receiveCoupon(
        item.couponId,
        { activityId: "" },
        (data) => {
          if (data.data) {
            this.getCouponList(true);
            this.$toast("领取成功");
          }
        },
        (error) => {
          if (error.includes("@_@")) {
            this.$toast(error.split("@_@")[1]);
          } else {
            error && this.$toast(error);
          }
        }
      );
    }
    if (item.receivedType === "3") {
      this.$toast("券被抢光了，去看看其他优惠吧~");
    }
  }
  goUse(item: CouponDtos): void {
    this.couponType = this.getCouponType(
      item.orderAmountType!,
      item.orderAmount!,
      item.parValue!,
      item.batchClass!,
      item.discountDescription!
    );
    this.$router.push(
      `/coupons-use?couponId=${item.couponId}&couponType=${this.couponType}`
    );
  }
  // 4.6.0 打折券优化
  getCouponType(
    orderAmountType: number,
    orderAmount: number,
    parValue: number,
    batchClass: string,
    discountDescription: string
  ): string {
    let text = "";
    if (orderAmountType === 1) {
      text = `${discountDescription}无门槛`;
    } else {
      text =
        Number(batchClass) === 6
          ? `满${orderAmount}打${discountDescription}`
          : `满${orderAmount}减${parValue}`;
    }
    return text;
  }
  onClickLeft(): void {
    if (this.AppSource == "harmonyOS") {
      this.jsBridge("back", "", () => {
        this.$router.go(-1);
      });
    } else {
      this.$router.go(-1);
    }
  }
  goDetail(item: CouponDtos): void {
    this.couponId = item.couponId;
    this.showDetail = true;
    this.effectTime = item.expireTimeStr;
  }
  formDate(date: string): string {
    const dt = new Date(Number(date));
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    const hh = (dt.getHours() + "").padStart(2, "0");
    const mm = (dt.getMinutes() + "").padStart(2, "0");
    // const ss = (dt.getSeconds() + "").padStart(2, "0");

    return `${y}.${m}.${d}  ${hh}:${mm}`;
  }
}
