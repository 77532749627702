




















import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import UserCheck from "@/views/Login/UserCheck.vue";
import wx from "weixin-js-sdk";
@Component({
  components: {
    UserCheck,
  },
})
export default class ExternalUserCheck extends Mixins(Mixin) {
  public showInfo: string[] = [];
  public chooseSeat = false;

  @Watch("ExternalAccessModule.isExternalAccessReady")
  readyStateChange(isExternalAccessReady: boolean): void {
    if (isExternalAccessReady) {
      this.openUserCheck();
    }
  }
  created(): void {
    let showInfo: any = this.$route.query.showInfo;
    this.chooseSeat = this.$route.query.chooseSeat === "1";
    try {
      this.showInfo = JSON.parse(showInfo);
    } catch (e) {}
  }
  mounted(): void {
    if (this.ExternalAccessModule.isExternalAccessReady) {
      this.openUserCheck();
    }
  }
  /**
   * 打开滑块验证码
   */
  openUserCheck(): void {
    this.$nextTick(() => {
      let userCheck = this.$refs["user-check"] as UserCheck;
      if (userCheck) {
        userCheck.show();
      }
    });
  }
  // 人机校验对话框确定
  getUserToken(data: order.CaptchaRequest): void {
    try {
      const params: any = this.$route.query.params;
      const form = JSON.parse(params);
      if (this.chooseSeat) {
        this.$api.orderApi.ticketOrder.lockSeatChoose(
          {
            captchaRequest: data,
            ...form,
          },
          ({ data }) => {
            if (this.ChannelModule.channel == "plat_wechat_miniapp") {
              const theaterId = this.$route.query.theaterId;
              wx.miniProgram.redirectTo({
                url: `/pagesOrder/confirmOrder/confirmOrder?uuid=${data}${
                  theaterId !== "40" ? "$theaterId=" + theaterId : ""
                }`,
              });
            } else if (this.ChannelModule.channel == "douyin_miniapp") {
              window.tt.miniProgram.redirectTo({
                url: "/pages/confirm/confirm?uuid=" + data,
              });
            }
          },
          (error) => {
            this.$toast(error);
            if (error === "滑动验证结果不正确") {
              this.openUserCheck();
            } else {
              setTimeout(() => {
                if (this.ChannelModule.channel == "plat_wechat_miniapp") {
                  wx.miniProgram.navigateBack();
                } else if (this.ChannelModule.channel == "douyin_miniapp") {
                  window.tt.miniProgram.navigateBack();
                }
              }, 2000);
            }
          }
        );
      } else {
        this.$api.orderApi.ticketOrder.lockSeatNow(
          {
            captchaRequest: data,
            ...form,
          },
          ({ data }) => {
            if (this.ChannelModule.channel == "plat_wechat_miniapp") {
              const theaterId = this.$route.query.theaterId;
              wx.miniProgram.redirectTo({
                url: `/pagesOrder/confirmOrder/confirmOrder?uuid=${data}${
                  theaterId !== "40" ? "$theaterId=" + theaterId : ""
                }`,
              });
            } else if (this.ChannelModule.channel == "douyin_miniapp") {
              window.tt.miniProgram.redirectTo({
                url: "/pages/confirm/confirm?uuid=" + data,
              });
            }
          },
          (error) => {
            this.$toast(error);
            if (error === "滑动验证结果不正确") {
              this.openUserCheck();
            } else {
              setTimeout(() => {
                if (this.ChannelModule.channel == "plat_wechat_miniapp") {
                  wx.miniProgram.navigateBack();
                } else if (this.ChannelModule.channel == "douyin_miniapp") {
                  window.tt.miniProgram.navigateBack();
                }
              }, 2000);
            }
          }
        );
      }
    } catch (e) {
      this.$toast("失败");
      setTimeout(() => {
        if (this.ChannelModule.channel == "plat_wechat_miniapp") {
          wx.miniProgram.navigateBack();
        } else if (this.ChannelModule.channel == "douyin_miniapp") {
          window.tt.miniProgram.navigateBack();
        }
      }, 2000);
    }
  }
}
