








































import { Mixin } from "@/core/mixins/mixin";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { Button, Icon, Image, Popup } from "vant";
import CouponList from "@/views/Mine/AssetCenter/Coupon/CouponCenter.vue";

@Component({
  components: {
    Mixin,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Popup.name]: Popup,
    CouponList,
  },
})
export default class CouponLabel extends Mixins(Mixin) {
  @Prop({ type: Array, default: [] })
  simpleCoupons!: Array<good.SimpleCouponDto>;

  public showCoupon = false;
  openCouponPopup(): void {
    if (this.AppSource == "harmonyOS" && !this.UserModule.isLogin) {
      this.jsBridge("needLogin", "", () => {
        this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
          router: this.$router,
          newPage: true,
          goBackAfterLogin: true,
        });
      });
    } else {
      this.showCoupon = true;
    }
  }
}
